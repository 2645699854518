import createError from "http-errors";
import axios from "axios";
import config from "../environments";

// const apiUrl = "http://localhost:8080";
const apiUrl = config.API_URL;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                console.log("refreshing token.");
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        console.log("The request was successful.");
                        originalRequest.headers["authorization"] =
                            "Bearer " + token;
                        return axios(originalRequest);
                    })
                    .catch((err) => {
                        console.log("The request failed.");
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const refreshToken = window.localStorage.getItem("refreshToken");
            console.log(`Refresh Token: ${refreshToken}`);
            return new Promise(function (resolve, reject) {
                const refreshTokenUrl = new URL(
                    "/admin/auth/refreshlogin",
                    apiUrl
                );
                axios
                    .post(refreshTokenUrl.href, { refreshToken })
                    .then(({ data }) => {
                        window.localStorage.setItem(
                            "accessToken",
                            data.accessToken
                        );
                        window.localStorage.setItem(
                            "refreshToken",
                            data.refreshToken
                        );
                        axios.defaults.headers.common["authorization"] =
                            "Bearer " + data.accessToken;
                        originalRequest.headers["authorization"] =
                            "Bearer " + data.accessToken;
                        processQueue(null, data.accessToken);
                        resolve(axios(originalRequest));
                    })
                    .catch((err) => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(error);
    }
);

const get = async (uri, data) => {
    try {
        const url = new URL(uri, apiUrl);
        for (const key in data) url.searchParams.append(key, data[key]);
        const response = await axios.get(url.href);
        return response;
    } catch (error) {
        if (error.response) {
            throw createError(
                error?.response?.status || 400,
                `${error?.response?.data?.error?.message}`
            );
        } else {
            // Something happened in setting up the request that triggered an Error
            throw createError(408, "Request Timedout");
        }
    }
};

const post = async (uri, data) => {
    try {
        const url = new URL(uri, apiUrl);
        const response = await axios.post(url.href, data);
        return response;
    } catch (error) {
        if (error.response) {
            // Request made and server responded
            throw createError(
                error?.response?.status || 400,
                `${error?.response?.data?.error?.message}`
            );
        } else {
            // Something happened in setting up the request that triggered an Error
            throw createError(408, "Request Timedout");
        }
    }
};

const axiosDelete = async (uri, data) => {
    try {
        const url = new URL(uri, apiUrl);
        return await axios.delete(url.href, { data });
    } catch (error) {}
};

const postProtected = async (uri, data) => {
    try {
        const accessToken = window.localStorage.getItem("accessToken");
        const config = {
            headers: { authorization: `Bearer ${accessToken}` },
        };
        const url = new URL(uri, apiUrl);
        const response = await axios.post(url.href, data, config);
        return response;
        // try {
        // } catch (error) {
        //     const existingRefreshToken =
        //         window.localStorage.getItem("refreshToken");
        //     const tokenUrl = new URL("/admin/auth/refreshlogin", apiUrl);
        //     const tokens = await axios.post(tokenUrl.href, {
        //         refreshToken: existingRefreshToken,
        //     });
        //     const { accessToken, refreshToken } = tokens.data;
        //     localStorage.setItem("accessToken", accessToken);
        //     localStorage.setItem("refreshToken", refreshToken);

        //     const config = {
        //         headers: { authorization: `Bearer ${accessToken}` },
        //     };
        //     const response = await axios.post(url.href, data, config);
        //     return response;
        // }
    } catch (error) {
        console.log(error);
    }
};

export { get, post, axiosDelete, postProtected, apiUrl };
